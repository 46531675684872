export default {
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позвонить"])},
  "call_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратный звонок"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "all_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все новости"])},
  "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["карта проезда"])},
  "studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Студия «Зіна дизайн»"])},
  "studio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разработка сайта. Днепр, 2021"])},
  "question_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОСТАЛИСЬ ВОПРОСЫ?"])},
  "push_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для заполнения формы обратной связи"])},
  "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Имя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контактный email"])},
  "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
  "OUR REPRESENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАШЕ ПРЕДСТАВИТЕЛЬСТВО"])},
  "your_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш заказ"])},
  "order_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать товар"])},
  "order_goods_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать услугу"])},
  "order_a_call_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать обратный звонок"])},
  "wait_for_a_call_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ждите звонок в ближайшее время"])},
  "thank_you_for_your_request_wait_for_a_call_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за заказ, ждите звонок в ближайшее время"])},
  "to_place_your_order_please_fill_out_the_form_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для оформления Вашего заказа, заполните пожалуйста форму обратной связи"])},
  "your_name_and_patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Имя"])},
  "your_contact_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контактный email"])},
  "buy_extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить дополнительно"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
  "InformationalSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационная безопастность"])},
  "NetworkInfrastructure Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сетевая инфраструктура"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
  "your_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш комментарий"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш комментарий"])},
  "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["услугу"])},
  "Home Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "IpTelephony Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip - ATC"])},
  "Search Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "ItInfrastructure Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT - инфраструктура"])},
  "BatchSolutions Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакетные решения"])},
  "ClientsAndPartners Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты и партнеры"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "Set Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплектующие"])},
  "Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог"])},
  "BlogHumor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юмор"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог"])},
  "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить еще"])},
  "BlogInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерестная информация"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "Position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])},
  "AboutCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про компанию"])},
  "ClientsAndPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты и партнеры"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по сайту"])},
  "Found in sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдено в разделах"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искать"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["результаты"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["результатов"])},
  "other_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["другие новости"])},
  "view_all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все услуги"])},
  "order_a_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать услугу"])},
  "Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдено"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисные услуги"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "Enter a query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите запрос"])},
  "Sorry, nothing was found for your query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, по Вашему запросу ничего не найдено"])},
  "Nothing found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])},
  "Please enter search words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите, пожалуйста, поисковые слова"])},
  "There are results on your request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По Вашему запросу Есть результаты"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от"])}
}